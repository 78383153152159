<template>
  <div class="sctp-bg-white sctp-pad-tb20">
    <el-form ref="userInfoForm" :model="userInfoForm" label-width="120px"
             :rules="userInfoFormRules" class="userInfoForm">
      <el-form-item label="登录账号">
        {{ userInfoForm.account }} (ID: {{ userInfoForm.userId }})
        <template v-if="userInfoForm.userTypeStr">
          <el-tag
            size="small"
            :effect="userInfoForm.userTypeStr.includes('未') ? 'dark' : 'light' "
            :type="userInfoForm.userTypeStr.includes('未') ? 'danger' : 'success'"
          >
            {{ userInfoForm.userTypeStr }}
          </el-tag>
        </template>
        <template v-if="userInfoForm.phone">
          <span class="mg-l20">注册绑定手机号：{{ userInfoForm.phone }}</span>
        </template>
      </el-form-item>
      <el-form-item class="w500" label="真实姓名" prop="username">
        <el-input :readonly="!userInfoForm.canRename" :disabled="!userInfoForm.canRename"
                  v-model="userInfoForm.username"></el-input>
      </el-form-item>
      <el-form-item class="w500" label="身份证号" prop="username">
        <el-input :readonly="!userInfoForm.canRename" :disabled="!userInfoForm.canRename"
                  v-model="userInfoForm.idcard"></el-input>
      </el-form-item>
      <el-form-item class="w500" label="昵称" prop="nickname">
        <el-input v-model="userInfoForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="userInfoForm.gender">
          <el-radio label="M">男</el-radio>
          <el-radio label="F">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="地区" prop="area">
        <el-cascader
          style="width: 300px;"
          :options="area"
          v-model="userInfoForm.area"></el-cascader>
      </el-form-item>
      <el-form-item class="w500" label="QQ号码" prop="qqNumber">
        <el-input :readonly="userInfoForm.qqAuth === 1"
                  v-model="userInfoForm.qqNumber">
        </el-input>
        <div style="position: absolute; top: 0; left: calc(100% + 10px)">
          <template v-if="userInfoForm.qqAuth === 0">
            <el-tooltip effect="dark" content="待验证" placement="top">
              <i class="el-icon-info fz-20"></i>
            </el-tooltip>
          </template>
          <template v-if="userInfoForm.qqAuth === 1">
            <el-tooltip effect="dark" content="已验证" placement="top">
              <i class="el-icon-success sctp-color-main fz-20"></i>
            </el-tooltip>
          </template>
          <template v-if="userInfoForm.qqAuth === 2">
            <el-tooltip effect="dark" content="验证未通过，请重新输入QQ号" placement="top">
              <i class="el-icon-error fz-20" style="color: #F56C6C;"></i>
            </el-tooltip>
          </template>
          <template v-if="userInfoForm.qqAuth === 3">
            <el-tooltip effect="dark" content="待用户接收QQ邮箱验证" placement="top">
              <i class="el-icon-warning fz-20"></i>
            </el-tooltip>
          </template>
        </div>
      </el-form-item>
      <el-form-item class="w500" label="微信号码" prop="wxNumber">
        <el-input :readonly="userInfoForm.wxAuth === 1"
                  v-model="userInfoForm.wxNumber" maxlength="20">
          <template slot="append" v-if="userInfoForm.wxAuth !== 1">
            <a @click="userInfoForm.wxNumber = userInfoForm.phone"><i
              class="el-icon-thumb mg-r5"></i>手机同号</a>
          </template>
        </el-input>
        <div style="position: absolute; top: 0; left: calc(100% + 10px)">
          <template v-if="userInfoForm.wxAuth === 0">
            <el-tooltip effect="dark" content="待验证" placement="top">
              <i class="el-icon-info fz-20"></i>
            </el-tooltip>
          </template>
          <template v-if="userInfoForm.wxAuth === 1">
            <el-tooltip effect="dark" content="已验证" placement="top">
              <i class="el-icon-success sctp-color-main fz-20"></i>
            </el-tooltip>
          </template>
          <template v-if="userInfoForm.wxAuth === 2">
            <el-tooltip effect="dark" :content="userInfoForm.wxAuthMemo" placement="top">
              <i class="el-icon-error fz-20" style="color: #F56C6C;"></i>
            </el-tooltip>
          </template>
        </div>
      </el-form-item>
      <el-form-item label="微信二维码">
        <el-upload
          :disabled="userInfoForm.wxQrcodeAuth === 1"
          :http-request="fileUploadFunction"
          :data="{...uploadEnum.USER}"
          action=""
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess">
          <div
            v-if="picture.wxImgUrl"
            style="width: 150px; height: 150px;position: relative"
          >
            <el-image
              :src="picture.wxImgUrl"
              style="width: 150px; height: 150px"
              fit="cover">
            </el-image>

          </div>
          <el-button v-else size="small" type="primary">选取文件</el-button>
          <div slot="tip" class="el-upload__tip">注：只能上传jpg/png文件，文件大小1M内，建议格式300X300px</div>
        </el-upload>
        <div style="position: absolute; top: 10px; left: calc(16% + 10px);">
          <template v-if="userInfoForm.wxQrcodeAuth === 0">
            <el-tooltip effect="dark" content="待验证" placement="top">
              <i class="el-icon-info fz-20"></i>
            </el-tooltip>
          </template>
          <template v-if="userInfoForm.wxQrcodeAuth === 1">
            <el-tooltip effect="dark" content="已验证" placement="top">
              <i class="el-icon-success sctp-color-main fz-20"></i>
            </el-tooltip>
          </template>
          <template v-if="userInfoForm.wxQrcodeAuth === 2">
            <el-tooltip effect="dark" :content="userInfoForm.wxAuthMemo" placement="top">
              <i class="el-icon-error fz-20" style="color: #F56C6C;"></i>
            </el-tooltip>
          </template>
        </div>
      </el-form-item>
      <el-form-item class="w500" label="个人介绍">
        <el-input
          type="textarea"
          :maxlength="200"
          :autosize="{ minRows: 2, maxRows: 6}"
          placeholder="一句话介绍自己..."
          v-model="userInfoForm.introduction">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="onSubmitForm('userInfoForm')" size="small" type="primary"
                   class="sctp-square">保存修改
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import area from '@/resources/js/area'
import validator from '@/resources/js/async-validator'
import uploadEnum from "@/resources/js/uploadenum";
import {user} from '@/apis/index'

const config = require('@/resources/js/config')
export default {
  name: 'information',
  data() {
    return {
      uploadEnum,
      userType: {
        1: '个人',
        2: '企业',
        3: '个人-企业'
      },
      area: area.options,
      picture: {
        wxImgUrl: null
      },
      userInfoForm: {
        account: null,
        nickname: null,
        email: null,
        idcard:null,
        phone: null,
        qqNumber: null,
        wxNumber: null,
        wxImgUrl: null,
        area: null, // 地区
        introduction: null
      },
      userInfoFormRules: {
        nickname: [
          {required: true, message: '昵称不能为空'}
        ],
        username: [
          {required: true, message: '真实姓名不能为空'}
        ],
        gender: [
          {required: true, message: '请选择性别'}
        ],
        area: [
          {required: true, message: '请选择地区'}
        ],
        qqNumber: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                validator.validQQ(rule, value, callback)
              }
              callback()
            },
            message: 'qq号码不正确'
          }
        ],
        wxNumber: [
          {required: true, message: '请输入微信号码', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (value.length == 11 && !/^[A-Za-z]/.test(value)){
                  if (!/^1[3456789]\d{9}$/.test(value)){
                    callback(new Error('输入的手机号格式有误'));
                  }else {
                    callback()
                  }
                }
                validator.validWeiChat(rule, value, callback)
              }
              callback()
            },
            message: '微信号码不正确'
          }
        ]
      }
    }
  },
  methods: {
    onSubmitForm(formName) {
      this.$refs[formName].validate().then(res => {
        this.updateUserInfo()
      }).catch(() => {
        this.$message.warning('请完成表单')
      })
    },
    updateUserInfo() {
      const formData = JSON.parse(JSON.stringify(this.userInfoForm))
      this.$request.post({
        reqcode: '1043',
        reqdesc: '修改用户信息',
        username: formData.username,
        usernice: formData.nickname,
        qqcode: formData.qqNumber,
        idcard: formData.idcard,
        wxcode: formData.wxNumber,
        area: formData.area,
        introduce: formData.introduction,
        userId: this.user.userId,
        gender: formData.gender

      }).then(res => {
        this.$message.success('保存成功')
        this.getUserInfo()
      })
    },
    getUserInfo() {
      user.userInfo({
        userId: this.user.userId
      }).then(res => {
        const user = res.data
        const form = {}
        const picture = {
          wxImgUrl: null
        }

        form.userId = user.userId;
        form.username = user.username || '';
        form.idcard = user.idcard;
        form.account = user.account || '';
        form.nickname = user.usernice || '';
        form.email = user.email || '';
        form.phone = user.phone || '';
        form.qqNumber = user.qqcode || '';
        form.wxNumber = user.wxcode || '';
        form.introduction = user.introduce || '';
        form.wallet = user.wallet || 0;
        form.userType = user.account_type;
        form.gender = user.gender;
        form.canRename = user.idcardAuthflag === 3;
        form.qqAuth = user.qqcode && user.qqcode_authflag;
        form.wxAuth = user.wxcode && user.wxcode_authflag;
        form.wxQrcodeAuth = user.wxqrcode && user.wxqrcode_authflag;
        form.userTypeStr = user.userTypeStr;
        form.wxAuthMemo = user.wxqrcode_authmemo;

        if (user.wxqrcode) {
          form.wxImgUrl = user.wxqrcode
          picture.wxImgUrl = user.wxQrcodePath
        }

        form.area = []
        if (user.provinceid) {
          form.area.push(user.provinceid)
        }
        if (user.cityid) {
          form.area.push(user.cityid)
        }
        if (user.regionid) {
          form.area.push(user.regionid)
        }
        if (form.area.length === 0) {
          form.area = null
        }

        this.userInfoForm = form
        this.picture = picture
        this.$nextTick(() => {
          this.$refs.userInfoForm.clearValidate();
        })
      })
    },
    beforeUpload(file) {
      const isImage = file.type.startsWith('image')
      const isLtSize = file.size / 1024 / 1024 < 1
      if (!isImage) {
        this.$message.error('上传图片只能是 图片 格式!')
      }
      if (!isLtSize) {
        this.$message.error('上传图片大小不能超过1M!')
      }
      return isImage && isLtSize && this.$message.info('正在上传') && true
    },
    uploadSuccess(res) {
      if (res.code) {
        const {src, preview} = res
        this.picture.wxImgUrl = preview;
        this.userInfoForm.wxQrcodeAuth = 0;
        user.uploadWxQrcode({
          userId: this.user.userId,
          path: src
        }).then(res => {
          this.$message.success('上传成功');
        })
      } else {
        this.$message.error('上传文件失败')
      }
    }
  },
  computed: {},
  beforeMount() {
    this.getUserInfo()
  }
}
</script>

<style scoped lang="scss">
.userInfoForm {
}

.w500 {
  width: 500px;
}

</style>
